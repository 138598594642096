import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabIntelliDoseControllerKit from "../../Assets/Bluelab IntelliDose Controller Kit.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_IntelliDose_Controller_Kit = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 11,
      name: "Bluelab IntelliDose Controller Kit",
      price: 127008,
      image: BluelabIntelliDoseControllerKit,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabIntelliDoseControllerKit}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab IntelliDose Controller Kit</h2>
            <p className="product-price">₹{127008}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <h5>Intelligent Control for Your Grow System</h5>
              The Bluelab system offers advanced control and dosing capabilities
              to optimize plant growth and simplify management. It doses up to 9
              nutrients, additives, and pH, while also supporting various
              growing methods such as NFT, DFT/DWC, soil, media, drip, and
              aeroponics.
              <ul>
                <li>
                  <span className="fw-bold">
                    Doses 9 nutrients/additives and pH.
                  </span>
                </li>
                <li>
                  <span className="fw-bold">4 outputs (24 VDC)</span> available
                  for irrigation when not used for nutrients.
                </li>
                <li>
                  Supports <span className="fw-bold">proportional dosing</span>{" "}
                  for rapid adjustments between irrigations.
                </li>
                <li>
                  <span className="fw-bold">Sequential pump control</span>{" "}
                  ensures each additive is mixed properly.
                </li>
                <li>
                  Displays vital parameters:{" "}
                  <span className="fw-bold">EC, CF, TDS, °C, °F, and pH.</span>
                </li>
                <li>
                  Control different{" "}
                  <span className="fw-bold">day and night EC</span> settings.
                </li>
                <li>
                  <span className="fw-bold">Water level control</span> helps
                  manage EC by adding water.
                </li>
                <li>
                  Adjustable alarms and lockouts to prevent overdosing or
                  running dry.
                </li>
                <li>
                  Connects to Bluelab Power Relay for controlling pumps,
                  heaters, and chillers via a timer.
                </li>
              </ul>
              <h5>When Connected to Edenic:</h5>
              <ul>
                <li>Instant alarm alerts directly to your phone.</li>
                <li>Remote monitoring and control from anywhere.</li>
                <li>
                  Historical reporting to support better data-driven decisions.
                </li>
              </ul>
              <span style={{ fontStyle: "italic" }}>
                Requires Bluelab IntelliLink and internet access for full Edenic
                integration.
              </span>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_IntelliDose_Controller_Kit;
