import React, { useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import "../../App.css";
import accessory1 from "../../Assets/accessory1.jpeg";
import accessory2 from "../../Assets/accessory2.jpeg";
import accessory3 from "../../Assets/accessory3.jpeg";
import accessory4 from "../../Assets/accessory4.jpeg";
import accessory5 from "../../Assets/accessory5.jpeg";
import accessory6 from "../../Assets/accessory6.jpeg";
import accessory7 from "../../Assets/accessory7.jpeg";
import accessory8 from "../../Assets/accessory8.jpeg";
import accessory9 from "../../Assets/accessory9.jpeg";
import accessory10 from "../../Assets/accessory10.jpeg";
import accessory11 from "../../Assets/accessory11.jpeg";
import accessory12 from "../../Assets/accessory12.jpeg";
import accessory13 from "../../Assets/accessory13.jpeg";
import accessory14 from "../../Assets/accessory14.jpeg";
import accessory15 from "../../Assets/accessory15.jpeg";
import accessory16 from "../../Assets/accessory16.jpeg";
import accessory17 from "../../Assets/accessory17.jpeg";
import accessory18 from "../../Assets/accessory18.jpeg";
import accessory19 from "../../Assets/accessory19.jpeg";
import accessory20 from "../../Assets/accessory20.jpeg";
import accessory21 from "../../Assets/accessory21.jpeg";
import accessory22 from "../../Assets/accessory22.jpeg";
import accessory23 from "../../Assets/accessory23.jpeg";
import accessory24 from "../../Assets/accessory24.jpeg";
import accessory25 from "../../Assets/accessory25.jpeg";
import accessory26 from "../../Assets/accessory26.jpeg";
import accessory27 from "../../Assets/accessory27.jpeg";
import accessory28 from "../../Assets/accessory28.jpeg";
import accessory29 from "../../Assets/accessory29.jpeg";
import accessory30 from "../../Assets/accessory30.jpeg";
import accessory31 from "../../Assets/accessory31.jpeg";
import accessory32 from "../../Assets/accessory32.jpeg";
import accessory33 from "../../Assets/accessory33.jpeg";
import accessory34 from "../../Assets/accessory34.jpeg";

import CartModal from "../../Components/CartModal";

function Greenhouse_Accessories() {
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const productsPerPage = 12; // Number of products per page

  // List of products
  const products = [
    {
      id: 47,
      name: "Naturally ventilated polyhouse (Saw tooth shape)",
      price: 18816,
      image: accessory1,
      link: "/Product1",
      description: "Description for Product 1",
    },
    {
      id: 48,
      name: "Shade Net House",
      price: 18900,
      image: accessory2,
      link: "/Product2",
      description: "Description for Product 2",
    },
    {
      id: 49,
      name: "Anti Insect Net House",
      price: 15120,
      image: accessory3,
      link: "/Product3",
      description: "Description for Product 3",
    },
    {
      id: 50,
      name: "Poly House For Nursery Production",
      price: 7560,
      image: accessory4,
      link: "/Product4",
      description: "Description for Product 4",
    },
    {
      id: 51,
      name: "Flat Roof",
      price: 9072,
      image: accessory5,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 52,
      name: "Hobby Green House",
      price: 9072,
      image: accessory6,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 53,
      name: "Dome Shape Green House",
      price: 9072,
      image: accessory7,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 54,
      name: "Retractable Greenhouses",
      price: 9072,
      image: accessory8,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 55,
      name: "Mist Chamber",
      price: 9072,
      image: accessory9,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 56,
      name: "Insect Net House",
      price: 9072,
      image: accessory10,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 57,
      name: "MULTI SPAN GREEN HOUSE",
      price: 9072,
      image: accessory11,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 58,
      name: "HI-TECH POLY HOUSE",
      price: 9072,
      image: accessory12,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 59,
      name: "Snow Region Greenhouse",
      price: 9072,
      image: accessory13,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 60,
      name: "LOW TUNNEL",
      price: 9072,
      image: accessory14,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 61,
      name: "Commercial Green House",
      price: 9072,
      image: accessory15,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 62,
      name: "Walk in Hoop tunnel",
      price: 9072,
      image: accessory16,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 63,
      name: "DOUBLE SPAN GREENHOUSE",
      price: 9072,
      image: accessory17,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 64,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory18,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 65,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory19,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 66,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory20,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 67,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory21,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 68,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory22,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 69,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory23,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 70,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory24,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 71,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory25,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 72,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory26,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 73,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory27,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 74,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory28,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 75,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory29,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 76,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory30,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 77,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory31,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 78,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory32,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 79,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory33,
      link: "/Product5",
      description: "Description for Product 5",
    },
    {
      id: 80,
      name: "Gable shaped polyhouse",
      price: 9072,
      image: accessory34,
      link: "/Product5",
      description: "Description for Product 5",
    },
  ];

  // Calculate the number of pages
  const totalPages = Math.ceil(products.length / productsPerPage);

  // Get the products to display on the current page
  const startIndex = (currentPage - 1) * productsPerPage;
  const currentProducts = products.slice(
    startIndex,
    startIndex + productsPerPage
  );

  // Function to handle pagination
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        {currentProducts.map((product) => (
          <Col xs={12} sm={6} md={4} lg={3} key={product.id} className="mb-4">
            <Link
              to={product.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card className="shadow-sm product-card h-100">
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <Card.Body className="text-center">
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>Price: ₹{product.price}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>

      {/* Pagination Controls */}
      <Row className="justify-content-center my-4">
        <Col xs="auto">
          <Button
            variant="secondary"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
            className="mx-2"
          >
            Previous
          </Button>
        </Col>
        <Col xs="auto">
          <span>
            Page {currentPage} of {totalPages}
          </span>
        </Col>
        <Col xs="auto">
          <Button
            variant="secondary"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="mx-2"
          >
            Next
          </Button>
        </Col>
      </Row>

      {/* Cart Modal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </Container>
  );
}

export default Greenhouse_Accessories;
