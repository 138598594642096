import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import PumpandCassettereplacementforPeripodM3M4100mls from "../../Assets/Bluelab-Replacement-Medium-Pump-and-Cassette-100-ml-suitable_PUMPCONT-M_1.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Pump_and_Cassette_M3_M4 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 25,
      name: "Pump and Cassette replacement for Peripod M3/M4 – 100mls",
      price: 14616,
      image: PumpandCassettereplacementforPeripodM3M4100mls,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={PumpandCassettereplacementforPeripodM3M4100mls}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Pump and Cassette replacement for Peripod M3/M4 – 120mls
            </h2>
            <p className="product-price">₹{14616}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <ul>
                <li>PeriPod M3 compatibility only.</li>
                <li>
                  The Pump and Cassette replacement for PeriPod M3/M4 delivers a
                  120ml/min flow rate for precise nutrient and pH dosing.
                  Specifically designed for M3 and M4 PeriPods, it ensures
                  consistent and efficient operation in compact growing
                  environments.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Pump_and_Cassette_M3_M4;
