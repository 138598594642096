import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BlbPhPen from "../../Assets/bluelab-ph-pen.png";
import "../../App.css"; // Import your CSS file for styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Product5 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();
  const price = 9072; // Define the price as a constant

  const handleAddToCart = () => {
    const product = { id: 5, name: "Bluelab pH Pen", price, image: BlbPhPen };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={BlbPhPen} alt="Product 5" className="d-block w-100" />
              </div>
              {/* <div className="carousel-item">
                <img src={DwcImage} alt="Product 5" className="d-block w-100" />
              </div>
              <div className="carousel-item">
                <img src={DwcImage} alt="Product 5" className="d-block w-100" />
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab pH Pen</h2>{" "}
            {/* Update the product name */}
            <p className="product-price">₹{9072}</p>{" "}
            {/* Use the price constant */}
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h3>
                A Durable and Compact pH and Temperature Pen for Reliable
                Readings
              </h3>
              This robust and portable pen is perfect for quick and easy pH and
              temperature measurements directly from your solution. Built to be
              fully waterproof, it offers greater reliability in wet conditions
              compared to standard water-resistant devices. <p>Featuring a simple
              two-point calibration process for enhanced accuracy, it also
              includes automatic temperature compensation for consistent
              readings no matter the environment. With an auto-off function to
              extend battery life and a one-year warranty for added peace of
              mind, this pen comes ready to use with an included AAA
              battery—just power it on and go.</p>
              <h4 className="mt-3">Key features include:</h4>
              <ul>
                <li>Fast and convenient pH and temperature readings</li>
                <li>
                  Fully waterproof for reliable performance in all conditions
                </li>
                <li>Simple two-point calibration for accurate measurements</li>
                <li>
                  Automatic temperature compensation for precise results in any
                  environment
                </li>
                <li>Auto-off function to save battery life</li>
                <li>One-year warranty for peace of mind</li>
                <li>
                  Includes 1 x AAA battery, ready to use right out of the box
                </li>
              </ul>
            </p>{" "}
            {/* Update the product description */}
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Product5;
