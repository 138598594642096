import React, { useContext, useState } from "react";
import { CartContext } from "../../Contexts/CartContext";
import axios from "axios";
import emailjs from "emailjs-com"; // Import EmailJS
import "../../App.css";

const Checkout = () => {
  const { cartItems, updateQuantity, totalAmount } = useContext(CartContext);
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    houseNumber: "",
    area: "",
    city: "",
    state: "",
    pincode: "",
  });

  const [errors, setErrors] = useState({});
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  // Function to validate each field
  const validateField = (name, value) => {
    let error = "";
    switch (name) {
      case "fullName":
        if (!value.match(/^[a-zA-Z\s]+$/)) {
          error = "Full Name can only contain letters.";
        }
        break;
      case "phoneNumber":
        if (!value.match(/^\d{10}$/)) {
          error = "Contact Number must be exactly 10 digits.";
        }
        break;
      case "email":
        if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
          error = "Please enter a valid email address.";
        }
        break;
      case "houseNumber":
        if (!value) {
          error = "House No./Building Name is required.";
        }
        break;
      case "area":
        if (!value) {
          error = "Road Name/Colony is required.";
        }
        break;
      case "city":
        if (!value.match(/^[a-zA-Z\s]+$/)) {
          error = "City can only contain letters.";
        }
        break;
      case "state":
        if (!value.match(/^[a-zA-Z\s]+$/)) {
          error = "State can only contain letters.";
        }
        break;
      case "pincode":
        if (!value) {
          error = "Pincode is required.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  // Handle moving to the next step
  const handleNextStep = (e) => {
    e.preventDefault();

    // Validate all fields before proceeding to the next step
    const fieldErrors = {};
    Object.keys(address).forEach((field) => {
      const error = validateField(field, address[field]);
      if (error) fieldErrors[field] = error;
    });

    setErrors(fieldErrors);

    if (Object.keys(fieldErrors).length === 0) {
      setStep((prevStep) => (prevStep < 3 ? prevStep + 1 : prevStep)); // Increment step up to 3
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });

    if (value) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  // Razorpay payment integration
  const handleRazorpayPayment = async () => {
    const options = {
      key: "rzp_live_40PTwkE1n9MCd1",
      amount: totalAmount * 100,
      currency: "INR",
      name: "ROYAL GREEN HOUSE CONSTRUCTION(I) PVT. LTD.",
      description: "Order Payment",
      handler: async function (response) {
        alert(
          `Payment Successful! Payment ID: ${response.razorpay_payment_id}`
        );
        setPaymentSuccess(true);

        // Prepare order data
        const orderData = {
          fullName: address.fullName,
          phoneNumber: address.phoneNumber,
          areaColony: address.area,
          houseNumber: address.houseNumber,
          city: address.city,
          state: address.state,
          pincode: address.pincode,
          totalPrice: totalAmount,
          paymentMethod: "Razorpay",
          paymentStatus: "Success",
          cartItems: cartItems.map((item) => ({
            productName: item.name,
            quantity: item.quantity,
            price: item.price,
          })),
        };

        try {
          // Send order data to the backend
          const response = await axios.post(
            "https://royalgreenhouse.co.in/backend/purchase.php",
            orderData
          );

          if (response.data.status === "success") {
            alert("Your order has been successfully placed!");
            // Send email confirmation if needed
            await sendEmailConfirmation();
          } else {
            alert("Error saving order to backend: " + response.data.message);
          }
        } catch (error) {
          console.error("Error saving order:", error);
          alert("Error saving order to backend: " + error);
        }
      },
      prefill: {
        name: address.fullName,
        contact: address.phoneNumber,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: async function () {
          // Handle payment cancellation
          alert("Your payment has been successfully cancelled.");

          // Prepare order data with "Cancelled" status
          const cancelledOrderData = {
            fullName: address.fullName,
            phoneNumber: address.phoneNumber,
            areaColony: address.area,
            houseNumber: address.houseNumber,
            city: address.city,
            state: address.state,
            pincode: address.pincode,
            totalPrice: totalAmount,
            paymentMethod: "Razorpay",
            paymentStatus: "Cancelled",
            cartItems: cartItems.map((item) => ({
              productName: item.name,
              quantity: item.quantity,
              price: item.price,
            })),
          };

          try {
            // Send cancelled order data to the backend
            const response = await axios.post(
              "https://royalgreenhouse.co.in/backend/purchase.php",
              cancelledOrderData
            );

            if (response.data.status === "success") {
              alert("No order has been processed since the payment was cancelled.");
            } else {
              alert(
                "Error saving cancelled order to backend: " +
                  response.data.message
              );
            }
          } catch (error) {
            console.error("Error saving cancelled order:", error);
            alert("Error saving cancelled order to backend: " + error);
          }
        },
      },
      callback_url: async function () {
        // Handle payment failure
        alert("Payment failed or cancelled.");

        // Prepare order data with "Failed" status
        const failedOrderData = {
          fullName: address.fullName,
          phoneNumber: address.phoneNumber,
          areaColony: address.area,
          houseNumber: address.houseNumber,
          city: address.city,
          state: address.state,
          pincode: address.pincode,
          totalPrice: totalAmount,
          paymentMethod: "Razorpay",
          paymentStatus: "Failed",
          cartItems: cartItems.map((item) => ({
            productName: item.name,
            quantity: item.quantity,
            price: item.price,
          })),
        };

        try {
          // Send failed payment order data to the backend
          const response = await axios.post(
            "https://royalgreenhouse.co.in/backend/purchase.php",
            failedOrderData
          );

          if (response.data.status === "success") {
            alert("Failed payment order details saved to backend.");
          } else {
            alert(
              "Error saving failed payment order to backend: " +
                response.data.message
            );
          }
        } catch (error) {
          console.error("Error saving failed payment order:", error);
          alert("Error saving failed payment order to backend: " + error);
        }
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Function to send email confirmation
  const sendEmailConfirmation = () => {
    const templateParams = {
      to_name: address.fullName,
      to_email: address.email,
      total_amount: totalAmount,
      items: cartItems
        .map((item) => `${item.name} (Qty: ${item.quantity})`)
        .join(", "),
      address: `${address.houseNumber}, ${address.area}, ${address.city}, ${address.state}, ${address.pincode}`,
    };

    return emailjs
      .send(
        "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
        "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
        templateParams,
        "YOUR_USER_ID" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log(
            "Email sent successfully!",
            response.status,
            response.text
          );
          alert("Confirmation email sent successfully!");
        },
        (error) => {
          console.error("Failed to send email:", error);
          alert("Failed to send confirmation email.");
        }
      );
  };

  return (
    <div className="checkout-container">
      {step === 1 && (
        <div>
          <h2>Shipping Address</h2>
          <form onSubmit={handleNextStep}>
            <div className="input-field">
              <label
                htmlFor="fullName"
                className={address.fullName ? "float-label" : ""}
              >
                Full Name
              </label>
              <input
                type="text"
                name="fullName"
                value={address.fullName}
                onChange={handleChange}
                required
              />
              {errors.fullName && <p className="error">{errors.fullName}</p>}
            </div>

            <div className="input-field">
              <label
                htmlFor="phoneNumber"
                className={address.phoneNumber ? "float-label" : ""}
              >
                Contact Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={address.phoneNumber}
                onChange={handleChange}
                required
              />
              {errors.phoneNumber && (
                <p className="error">{errors.phoneNumber}</p>
              )}
            </div>

            <div className="input-field">
              <label
                htmlFor="email"
                className={address.email ? "float-label-border" : ""}
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                value={address.email}
                onChange={handleChange}
                required
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>

            <div className="input-field">
              <label
                htmlFor="houseNumber"
                className={address.houseNumber ? "float-label" : ""}
              >
                House No./Building Name
              </label>
              <input
                type="text"
                name="houseNumber"
                value={address.houseNumber}
                onChange={handleChange}
                required
              />
              {errors.houseNumber && (
                <p className="error">{errors.houseNumber}</p>
              )}
            </div>

            <div className="input-field">
              <label
                htmlFor="area"
                className={address.area ? "float-label" : ""}
              >
                Road Name/Colony
              </label>
              <input
                type="text"
                name="area"
                value={address.area}
                onChange={handleChange}
                required
              />
              {errors.area && <p className="error">{errors.area}</p>}
            </div>

            <div className="input-field">
              <label
                htmlFor="city"
                className={address.city ? "float-label" : ""}
              >
                City
              </label>
              <input
                type="text"
                name="city"
                value={address.city}
                onChange={handleChange}
                required
              />
              {errors.city && <p className="error">{errors.city}</p>}
            </div>

            <div className="input-field">
              <label
                htmlFor="state"
                className={address.state ? "float-label" : ""}
              >
                State
              </label>
              <input
                type="text"
                name="state"
                value={address.state}
                onChange={handleChange}
                required
              />
              {errors.state && <p className="error">{errors.state}</p>}
            </div>

            <div className="input-field">
              <label
                htmlFor="pincode"
                className={address.pincode ? "float-label" : ""}
              >
                Pincode
              </label>
              <input
                type="text"
                name="pincode"
                value={address.pincode}
                onChange={handleChange}
                required
              />
              {errors.pincode && <p className="error">{errors.pincode}</p>}
            </div>

            <button className="full-width-button" type="submit">
              Next
            </button>
          </form>
        </div>
      )}
      {step === 2 && (
        <div>
          <h2>Order Summary</h2>
          <ul>
            {cartItems.map((item) => (
              <li key={item.id} className="cart-item">
                <div className="order-summary-item">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="order-summary-image"
                  />
                  <div className="order-summary-details">
                    <h4 className="product-name">{item.name}</h4>
                    <div className="quantity-controls">
                      <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() =>
                          updateQuantity(item.id, item.quantity - 1)
                        }
                        disabled={item.quantity === 1}
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() =>
                          updateQuantity(item.id, item.quantity + 1)
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <div className="total-amount">
            <h3>Total Amount: ₹{totalAmount}</h3>
          </div>
          <button className="full-width-button" onClick={handleNextStep}>
            Proceed to Payment
          </button>
        </div>
      )}
      {step === 3 && (
        <div>
          <h2>Payment</h2>
          <button className="full-width-button" onClick={handleRazorpayPayment}>
            Pay ₹ {totalAmount}
          </button>
          {paymentSuccess && <p>Payment Successful!</p>}
        </div>
      )}
    </div>
  );
};

export default Checkout;
