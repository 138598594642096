import React, { useState } from 'react';
import CartModal from '../../Components/CartModal';
import { useCart } from '../../Contexts/CartContext';
import BluelabProControllerConductivityProbe from '../../Assets/Bluelab Pro Controller Conductivity Probe.png';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Bluelab_Pro_Controller_Conductivity_Probe = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = { id: 19, name: 'Bluelab Pro Controller Conductivity Probe', price: 4872, image: BluelabProControllerConductivityProbe };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3"> {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div id="productCarousel" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={BluelabProControllerConductivityProbe} alt="Product 1" className="d-block w-100" />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a className="carousel-control-prev" href="#productCarousel" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#productCarousel" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Pro Controller Conductivity Probe</h2>
            <p className="product-price">₹{4872}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3">
              <h5>Replacement Conductivity Probe for Bluelab Pro Controller</h5>
              <ul>
                <li>Simple to clean and maintain</li>
                <li>Requires minimal upkeep</li>
                <li>Fully submersible for hassle-free use</li>
                <li>Comes with a 2-meter (6.56-foot) standard cable</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* Conditionally render the CartModal based on showModal */}
      {showModal && (
        <CartModal onClose={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default Bluelab_Pro_Controller_Conductivity_Probe;
