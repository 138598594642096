import React, {useState} from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

function Login(props) {
const [username, setUsername] = useState();
const [password, setPassword] = useState();
const navigate = useNavigate()
  const submit = (e) =>{
    e.preventDefault();
    async function login(){
        var x = await fetch("//royalgreenhouse.co.in/crud.php", {
        method: 'POST',
        body: JSON.stringify({ username: username, password: password }),
      })
      .then(res => res.json())
      .then(res => res)
      .catch(error => "error")
      if(x==true){
        console.log("succesful login")
        props.setLogin(true);
        navigate('/dashboard', {replace: true})
      } else if(x==false){
        console.log("login failed")
        props.setLogin(false);

      } else {
        console.log("technical error")
      }
    }
    login()
  }
    return (
        <>
            <Container fluid className=' '>
                <Row>
                    <Col className='m-4 d-flex justify-content-center align-items-center '>
                        <Form action='#' onSubmit={(e)=>submit(e)} className='border m-3 p-3 bg-info rounded'>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" value={username} placeholder="Enter Username" onChange={(e)=>setUsername(e.target.value)} required />

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" value={password} placeholder="Password" onChange={(e)=>setPassword(e.target.value)} required />
                            </Form.Group>

                            <Button variant="success" type="submit" >
                                Submit
                            </Button>
                        </Form>

                    </Col>
                </Row>


            </Container>

        </>
    )

}

export default Login;