import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabpHProbe5M from "../../Assets/Bluelab pH Probe 5M.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_pH_Probe_5ms = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 36,
      name: "Bluelab pH Probe 5M",
      price: 7140,
      image: BluelabpHProbe5M,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabpHProbe5M}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab pH Probe 5M</h2>
            <p className="product-price">₹{7140}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Durable Double-Junction pH Probe Technology for Longevity</h5>
              This robust pH probe is designed for reliability and long-lasting
              performance, making it an essential tool for growers. The
              <span className="fw-bold">double-junction probe</span>{" "}
              significantly reduces reference contamination, ensuring extended
              longevity and precise pH readings over time.
              <h5 className="mt-3">Key Features:</h5>
              <ul>
                <li>
                  Double-junction probe reduces contamination, enhancing the
                  probe's lifespan and accuracy.
                </li>
                <li>
                  Fully waterproof design, providing durability even in
                  submerged conditions.
                </li>
                <li>
                  BNC connector allows compatibility with Bluelab, Autogrow, and
                  other industry-standard devices.
                </li>
                <li>
                  Equipped with a 5-metre (16 ft) cable, ideal for measuring in
                  hard-to-reach areas and convenient cable management.
                </li>
                <li>
                  Storage cap with KCl is included to help extend the lifespan
                  of the probe when not in use.
                </li>
                <li>
                  Fast sensor response delivers quick and accurate measurements,
                  saving time during use.
                </li>
                <li>
                  Built with polycarbonate housing for enhanced durability and
                  long-lasting strength.
                </li>
                <li>
                  Effective in nutrient solutions of 0.2 EC and above for
                  versatile application in a wide range of growing environments.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_pH_Probe_5ms;
