import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabpHProbeKClStorageSolution from "../../Assets/Bluelab pH Probe KCl Storage Solution - 120 ml.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_pH_Probe_KCl_Storage_Solution = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 41,
      name: "Bluelab pH Probe KCl Storage Solution - 120 ml",
      price: 6804,
      image: BluelabpHProbeKClStorageSolution,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabpHProbeKClStorageSolution}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab pH Probe KCl Storage Solution - 120 ml
            </h2>
            <p className="product-price">₹{6804}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              The Perfect Solution for Storing and Hydrating Your Bluelab pH
              Probes or Pens.
              <h5 className="mt-3">Specification:</h5>
              <ul>
                <li>Type: A clean probe is an accurate probe.</li>
                <li>
                  Includes pH probe KCl storage solution (MSDS available).
                </li>
                <li>
                  Refer to the following cleaning instructions for maintenance:
                  <ul>
                    <li>Bluelab pH Pen Cleaning Instructions</li>
                    <li>Bluelab pH & Soil pH Probe Cleaning Instructions</li>
                    <li>Bluelab Soil pH Pen Cleaning Instructions</li>
                  </ul>
                </li>
              </ul>
              <h5 className="mt-3">Product Features:</h5>
              <ul>
                <li>Contains 100 ml / 3.3 fl oz of solution.</li>
                <li>
                  Compatible with:
                  <ul>
                    <li>Bluelab pH pens</li>
                    <li>Bluelab soil pH pens</li>
                    <li>Bluelab pH probes</li>
                    <li>Bluelab soil pH probes</li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_pH_Probe_KCl_Storage_Solution;
