import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCart } from "../../Contexts/CartContext";
import "../../App.css";

import BlbOnepen from "../../Assets/Bluelab OnePen.png";
import BlbStarterpen from "../../Assets/Bluelab pH and Conductivity Starter Pack.png";
import BlbSoilPhPen from "../../Assets/Bluelab Soil pH Pen.png";
import BlbConductivityPen from "../../Assets/bluelab-conductivity-pen.png";
import BlbPhPen from "../../Assets/bluelab-ph-pen.png";

import CartModal from "../../Components/CartModal";

function Automation_Products_Pens() {
  const [showModal, setShowModal] = useState(false);

  // List of products
  const products = [
    {
      id: 1,
      name: "Bluelab OnePen",
      price: 18816,
      image: BlbOnepen,
      link: "/Product1",
      description: "Description for Product 1",
    },
    {
      id: 2,
      name: "Bluelab pH and Conductivity Starter Pack",
      price: 18900,
      image: BlbStarterpen,
      link: "/Product2",
      description: "Description for Product 2",
    },
    {
      id: 3,
      name: "Bluelab Soil pH Pen",
      price: 15120,
      image: BlbSoilPhPen,
      link: "/Product3",
      description: "Description for Product 3",
    },
    {
      id: 4,
      name: "Bluelab Conductivity Pen",
      price: 7560,
      image: BlbConductivityPen,
      link: "/Product4",
      description: "Description for Product 4",
    },
    {
      id: 5,
      name: "Bluelab pH Pen",
      price: 9072,
      image: BlbPhPen,
      link: "/Product5",
      description: "Description for Product 5",
    },
  ];

  // Render the products in a centered grid layout
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        {products.map((product) => (
          <Col xs={12} sm={6} md={4} lg={3} key={product.id} className="mb-4">
            <Link
              to={product.link}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card className="shadow-sm product-card h-100">
                <Card.Img
                  variant="top"
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <Card.Body className="text-center">
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>Price: ₹{product.price}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>

      {/* Cart Modal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </Container>
  );
}

export default Automation_Products_Pens;
