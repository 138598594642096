import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import LargePumpCassetteonlysuitableforL3Peripods1200mls from "../../Assets/Bluelab-Large-Cassette-Only-1200ml-suitable_PUMPCASS-L_1.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Large_Pump_Cassette = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 22,
      name: "Large Pump Cassette - only suitable for L3 Peripods -1200mls",
      price: 22260,
      image: LargePumpCassetteonlysuitableforL3Peripods1200mls,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={LargePumpCassetteonlysuitableforL3Peripods1200mls}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Large Pump Cassette - only suitable for L3 Peripods -1200mls
            </h2>
            <p className="product-price">₹{22260}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              
              <ul>
                <li>PeriPod L3 compatibility only.</li>

                <li>
                  he Large Pump Cassette, specifically designed for L3 PeriPods,
                  delivers a high flow rate of 1200ml per minute, making it
                  ideal for dosing large volumes of nutrient solutions. It
                  ensures efficient and accurate nutrient delivery in hydroponic
                  systems requiring higher dosing capacities.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Large_Pump_Cassette;
