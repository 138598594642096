import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabIntelliLink from "../../Assets/Bluelab IntelliLink.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../../App.css";

const Bluelab_IntelliLink = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 12,
      name: "Bluelab IntelliLink",
      price: 14364,
      image: BluelabIntelliLink,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabIntelliLink}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab IntelliLink</h2>
            <p className="product-price">₹{14364}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>
                Seamlessly Integrate IntelliDose with Edenic Cloud Software
              </h5>
              With the IntelliLink, you can easily connect your Bluelab
              IntelliDose to the internet, enabling remote access through Edenic
              software. This integration allows you to manage your Intelli
              devices effortlessly.
              <ul className="mt-2">
                <li>
                  <span className="fw-bold">Remote Access:</span> Gain the ability to manage your systems via the
                  Edenic software from anywhere.
                </li>
                <li>
                <span className="fw-bold">Flexible Connectivity:</span> Connect your Bluelab IntelliDose
                  Controller to the internet using either an Ethernet cable or
                  Wi-Fi.
                </li>
                <li>
                <span className="fw-bold">Mobile Monitoring:</span> Access your Intelli data directly on your
                  mobile device for real-time insights.
                </li>
                <li>
                <span className="fw-bold">Adjust Recipes Remotely:</span> Change recipes or parameters from a
                  distance with ease.
                </li>
                <li>
                <span className="fw-bold">Unified Access:</span> Manage multiple devices with a single login
                  for convenience.
                </li>
              </ul>
              <span className="fw-bold mt-3">Note:</span> The use of the Edenic software app is required for this functionality.
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_IntelliLink;
