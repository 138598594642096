import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabpHProbe5M from "../../Assets/Bluelab pH Probe 5M.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../../App.css";
const Bluelab_pH_Probe_5M = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 16,
      name: "Bluelab pH Probe 5M",
      price: 7140,
      image: BluelabpHProbe5M,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabpHProbe5M}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab pH Probe 5M</h2>
            <p className="product-price">₹{7140}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Reliable Double-Junction pH Probe Designed for Durability</h5>
              <ul>
                <li>
                  The double-junction design helps prevent contamination,
                  significantly increasing the probe’s lifespan.
                </li>
                <li>
                  Completely waterproof for full submersion, unlike
                  water-resistant alternatives.
                </li>
                <li>
                  Features a BNC connector for seamless compatibility with
                  Bluelab and Autogrow devices.
                </li>
                <li>
                  Extended 5-meter (16 ft) cable allows easy access to
                  hard-to-reach areas and simplifies cable routing.
                </li>
                <li>
                  Includes a KCl storage cap to preserve the probe and maximize
                  its durability.
                </li>
                <li>
                  Quick response time ensures fast, accurate readings with every
                  use.
                </li>
                <li>
                  Constructed with strong polycarbonate housing for enhanced
                  durability.
                </li>
                <li>
                  Ideal for use in solutions with an electrical conductivity
                  (EC) of 0.2 or greater.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_pH_Probe_5M;
