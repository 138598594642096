import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabSampleTubeandFittings from "../../Assets/Bluelab Sample Tube and Fittings.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Sample_Tube_and_Fittings = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 21,
      name: "Bluelab Sample Tube and Fittings",
      price: 18144,
      image: BluelabSampleTubeandFittings,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabSampleTubeandFittings}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Sample Tube and Fittings</h2>
            <p className="product-price">₹{18144}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>Get Closer to Your Reservoir Parameters</h5>
              Compatible with Bluelab Guardian Monitor, Guardian Monitor
              Connect, and Pro Controller, this sample tube is ideal for
              situations where your reservoir is more than 2 meters (6.6 feet)
              away from the controller.
              <ul>
                <li>
                  Provides direct nutrient solution measurement by placing
                  probes into the tube ports
                </li>
                <li>Includes necessary fittings for easy setup</li>
              </ul>
              <i>
                Note: Not suitable for high-pressure systems. Maximum pressure
                rating: 15 psi.
              </i>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Sample_Tube_and_Fittings;
