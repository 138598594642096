import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BluelabCommercialTruncheonNutrientMeter from "../../Assets/Bluelab Commercial Truncheon Nutrient Meter.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Bluelab_Commercial_Truncheon_Nutrient_Meter = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 33,
      name: "Bluelab Commercial Truncheon Nutrient Meter",
      price: 11340,
      image: BluelabCommercialTruncheonNutrientMeter,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BluelabCommercialTruncheonNutrientMeter}
                  alt="Product 1"
                  className="d-block w-100"
                />
              </div>
              {/* Add more images to the carousel as needed */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">
              Bluelab Commercial Truncheon Nutrient Meter
            </h2>
            <p className="product-price">₹{11340}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h5>A Larger Scale EC Meter Built to Last a Lifetime</h5>
              Designed for unmatched durability and accuracy, this EC meter is
              now available with a larger measurement scale. It allows you to
              measure up to{" "}
              <span className="fw-bold">
                6.0 EC, 60 CF, 3000 ppm (PPM 500), or 4200 ppm (PPM 700)
              </span>
              , making it ideal for more demanding applications.
              <h5 className="mt-3">Key Benefits:</h5>
              <ul>
                <li>
                  Robust and reliable construction ensures long-lasting value,
                  even in harsh environments.
                </li>
                <li>
                  Fully waterproof—whether splashed, submerged, or sunk, it
                  performs reliably under all conditions.
                </li>
                <li>
                  Automatic liquid detection with bright LED indicators ensures
                  effortless, accurate measurements.
                </li>
                <li>
                  The long-format design enables easy mixing and measuring of
                  nutrient reservoirs all in one go.
                </li>
              </ul>
              Additionally, this meter is{" "}
              <span className="fw-bold">calibration-free</span>, making it easy
              to clean and disinfect for continuous use. All{" "}
              <span className="fw-bold">conductivity scales</span>—EC, CF, PPM
              500 (TDS), and PPM 700—are included for versatile measurement
              options. Backed by an industry-leading five-year warranty, you can
              be confident in its reliability.
              <ul>
                <li>
              <i className="mt-4">Note: Packaging may vary based on availability.</i>
              </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Bluelab_Commercial_Truncheon_Nutrient_Meter;
