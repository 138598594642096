import React, { useState } from "react";
import CartModal from "../../Components/CartModal";
import { useCart } from "../../Contexts/CartContext";
import BlbSoilPhPen from "../../Assets/Bluelab Soil pH Pen.png";
import "../../App.css"; // Import your CSS file for styles
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const Product3 = () => {
  const [showModal, setShowModal] = useState(false);
  const { addToCart } = useCart();

  const handleAddToCart = () => {
    const product = {
      id: 3,
      name: "Bluelab Soil pH Pen",
      price: 15120,
      image: BlbSoilPhPen,
    };
    addToCart({ ...product, quantity: 1 });
    setShowModal(true);
  };

  return (
    <div className="container mt-3">
      {" "}
      {/* Add margin top to the container */}
      <div className="row">
        <div className="col-md-6">
          <div
            id="productCarousel"
            className="carousel slide"
            data-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={BlbSoilPhPen}
                  alt="Product 3"
                  className="d-block w-100"
                />
              </div>
              {/* <div className="carousel-item">
                <img src={DwcImage} alt="Product 3" className="d-block w-100" />
              </div>
              <div className="carousel-item">
                <img src={DwcImage} alt="Product 3" className="d-block w-100" />
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#productCarousel"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#productCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-md-6">
          <div className="product-details">
            <h2 className="product-name">Bluelab Soil pH Pen</h2>{" "}
            {/* Update the product name */}
            <p className="product-price">₹{15120}</p>
            <button className="btn btn-primary" onClick={handleAddToCart}>
              Add to Cart
            </button>
            <p className="product-description mt-3 text-justify">
              <h3>
                This rugged, handheld solution is perfect for measuring soil pH
                and temperature on the go.
              </h3>
              Designed to be highly effective in various growing media,
              including water or nutrient solutions, it provides reliable and
              accurate readings in any environment. Fully waterproof, it stands
              up to the harshest conditions and comes with a one-year
              industry-leading warranty, giving you peace of mind. With
              user-friendly features such as a hold reading function for easy
              measurement capture and simple two-point calibration for enhanced
              accuracy, this device is built for convenience and precision. The
              automatic temperature compensation ensures accurate readings no
              matter where you are, and an auto-off feature extends battery
              life, making it ideal for prolonged use.
              <h4 className="mt-3">Key features include:</h4>
              <ul>
                <li>Fully waterproof for use in wet environments</li>
                <li>
                  Works effectively in most growing media and water/solution
                </li>
                <li>Hold reading function to capture measurements with ease</li>
                <li>Simple two-point calibration for increased accuracy</li>
                <li>
                  Automatic temperature compensation for consistent readings
                </li>
                <li>Auto-off function to save battery life</li>
                <li>Includes 1 x AAA alkaline battery, ready to use</li>
                <li>One-year industry-leading warranty for peace of mind</li>
              </ul>
            </p>{" "}
            {/* Update the product description */}
          </div>
        </div>
      </div>
      {/* Conditionally render the CartModal based on showModal */}
      {showModal && <CartModal onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default Product3;
